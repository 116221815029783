<!--
  添加服务类型（添加的就是三级分类）
    + 选择一级分类和二级分类
 -->
<template>
  <div class="step1">
    <el-form
      ref="elForm"
      :model="formData"
      :rules="rules"
      size="small"
      label-width="180px"
      label-position="left">
      <el-form-item
        label="一级分类"
        prop="parentId">
        <el-select
          v-model="formData.parentId"
          placeholder="请选择一级分类"
          style="width: 100%"
          @change="getServiceTypeOptions">
          <el-option
            v-for="item in serviceTypeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="二级分类"
        prop="twoParentId">
        <el-select
          v-model="formData.twoParentId"
          placeholder="请选择二级分类"
          style="width: 100%">
          <el-option
            v-for="item in twoServiceTypeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="类型名称"
        prop="name">
        <el-input
          maxlength="50" show-word-limit
          v-model="formData.name"
          placeholder="请输入服务类型名称">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" :loading="btnLoading" @click="submitForm">下一步</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import * as api from '@/api'
export default {
  data () {
    return {
      btnLoading: false,
      formData: {
        parentId: '',
        twoParentId: '',
        name: ''
      },
      rules: {
        parentId: [
          { required: true, message: '请选择所属一级分类', trigger: 'change' }
        ],
        twoParentId: [
          { required: true, message: '请选择所属二级分类', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入服务类型名称', trigger: 'blur' }
        ]
      },
      // 一级服务类型选项
      serviceTypeOptions: [],
      // 二级服务类型选项
      twoServiceTypeOptions: []
    }
  },
  created () {
    this.getServiceTypeOptions()
  },
  methods: {
    // 获取所属分类 -> 一级类型
    getServiceTypeOptions (parentId) {
      api.getServiceTypeOptions({
        parentId
      }).then(res => {
        if (res.data.code === 0) {
          const { list } = res.data.data
          if (parentId) {
            this.twoServiceTypeOptions = list
            this.formData.twoParentId = ''
          } else {
            this.serviceTypeOptions = list
          }
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 提交表单
    submitForm () {
      this.$refs.elForm.validate(valid => {
        if (valid) {
          this.addService()
        }
      })
    },
    // 添加服务类型
    addService () {
      this.btnLoading = true
      const { name, twoParentId: parentId } = this.formData
      api.addService({
        name,
        parentId
      }).then(res => {
        if (res.data.code === 0) {
          console.log('添加服务类型成功', res.data.data)
          this.$emit('changeStep', 1, res.data.data)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
