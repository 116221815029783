<!-- 添加字段 -->
<template>
  <el-dialog
    custom-class="my-el-dialog"
    title="新增流程"
    :visible.sync="$_visile"
    width="682px"
    :close-on-click-modal="false"
    @close="onDialogClose"
    @open="onDialogOpen">
    <el-form
      class="tw-px-20px"
      ref="form"
      :model="form"
      :rules="rules"
      label-position="left"
      label-width="180px"
      size="small">
      <el-form-item
        prop="name"
        label="流程名称">
        <el-input
          maxlength="50" show-word-limit
          style="width:380px;"
          v-model="form.name"
          placeholder="请输入流程名称">
        </el-input>
      </el-form-item>
      <!-- 表单组件 -->
      <custom-form
        :formModel="form.fields"
        :formData="fieldsFormData">
        <custom-form-item
          v-for="item in form.fields"
          :key="item.column"
          :item="item"
          :value.sync="fieldsFormData[item.column]">
          <div
            v-if="!item.isFixed"
            class="item_icon_box tw-ml-10px"
            @click="deleteItem(item)">
            <i class="iconfont iconshanchu"></i>
          </div>
        </custom-form-item>
      </custom-form>
      <el-form-item>
        <el-button size="small" @click="openAddFieldDialog"><i class="el-icon-plus"></i> 新增选项</el-button>
      </el-form-item>
    </el-form>

    <!-- 添加字段的弹窗 -->
    <add-field
      :visible.sync="addFieldDialogVisible"
      @addField="addField">
    </add-field>

    <div slot="footer" class="footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
      <el-button type="primary" size="small" :loading="saveBtnLoading" @click="saveForm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import addField from './add-field'
import customForm from '@/components/custom-form/index'
import customFormItem from '@/components/custom-form/custom-form-item'
export default {
  data () {
    return {
      // 新增表单项的弹窗
      addFieldDialogVisible: false,
      saveBtnLoading: false,
      // 流程表单
      form: {},
      // 字段表单
      fieldsFormData: {},
      rules: {
        name: [
          { required: true, message: '请输入流程名称', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    // 流程对象
    process: {
      type: Object,
      default: () => {
        return {
          id: '',
          name: '',
          isFixed: false,
          fields: []
        }
      }
    }
  },
  computed: {
    $_visile: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', false)
      }
    }
  },
  components: {
    addField,
    customForm,
    customFormItem
  },
  methods: {
    // 打开一个新增表单项的弹窗
    openAddFieldDialog () {
      this.addFieldDialogVisible = true
    },
    // 新增一个表单项
    addField (data) {
      this.form.fields.push(data)
      this.$set(this.fieldsFormData, data.column, null)
    },
    // 删除选项
    deleteItem (item) {
      const index = this.form.fields.findIndex(fItem => {
        return fItem.column === item.column
      })
      if (index !== -1) {
        this.form.fields.splice(index, 1)
        this.$delete(this.fieldsFormData, item.column)
      }
    },

    // 进行保存
    saveForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('callback', this.$clone(this.form))
          this.closeDialog()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    // 打开dialog
    // 关闭新建里程碑的dialog
    closeDialog () {
      this.$_visile = false
    },

    // dialog 打开事件
    onDialogOpen () {
      console.log('打开', this.process)
      this.form = this.$clone(this.process)
      this.form.fields.forEach(item => [
        this.$set(this.fieldsFormData, item.column, null)
      ])
    },
    // dialog关闭事件
    onDialogClose () {
      // 重置手段添加项目表单
      this.$refs.form.resetFields()
      this.fieldsFormData = {}
      this.form = {}
      this.$emit('onDialogClose')
    }
  }
}
</script>
<style lang="scss" scoped>
  .item_icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #DCDFE6;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: #fff;
      border-color: #3473E6;
      color: #3473E6;
    }
  }
</style>
