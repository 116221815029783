<!--
  定义流程，有两个固定流程
    + 已下单
    + 已结束
  后续的流程都是在其中插入
  点击编辑表单
    + 赋值当前流程
    + 打开弹窗，传入当前流程
    +
 -->
<template>
  <div class="step_three">
    <div>
      <div class="label_box">
        服务下单流程定义
      </div>
    </div>
    <div class="content_box">
      <div class="process_list">
        <div class="process_item"
          v-for="(item, index) in processList"
          :key="item.id">
          <div class="item_header">
            <h1>{{ index + 1 }}.{{ item.name }}</h1>
            <i class="el-icon-close" v-if="!item.isFixed" @click="deleteProcess(item)"></i>
          </div>
          <div class="item_content">
            <p><span>交付物</span><span @click="editProcess(item, index)">编辑表单</span></p>
          </div>
        </div>
      </div>
      <div class="tw-mt-30px">
        <el-button size="small" @click="openAddProcessDialog"><i class="el-icon-plus"></i> 添加流程</el-button>
      </div>

      <div class="tw-mt-40px">
        <el-button size="small" type="primary" :loading="btnLoading" @click="addServiceProcess">完成定义</el-button>
      </div>
    </div>

    <!-- 添加流程 -->
    <add-process
      :process="curProcess"
      :visible.sync="addProcessDialogVisible"
      @callback="addAndEditProcess"
      @onDialogClose="onAddProcessDialogClose">
    </add-process>
  </div>
</template>

<script>
import * as api from '@/api'
import { defaultProcess } from '../utils/default.process'
import { getUid } from '@/utils/utils'
import addProcess from './add-process'
export default {
  data () {
    return {
      btnLoading: false,
      // 添加及编辑流程的dialog
      addProcessDialogVisible: false,
      // 当前被编辑的流程对象
      curProcess: {
        id: '',
        name: '',
        isFixed: false,
        fields: []
      },
      // 当前被编辑的流程索引
      curProcessIndex: -1,
      // 流程列表
      processList: [
        ...defaultProcess
      ]
    }
  },
  props: {
    // 服务类型id
    serviceTypeId: {
      type: String,
      default: ''
    }
  },
  components: {
    addProcess
  },
  methods: {
    // 更新自定义表单
    addServiceProcess () {
      this.btnLoading = true
      api.addServiceProcess({
        serviceTypeId: this.serviceTypeId,
        process: this.processList
      }).then(res => {
        if (res.data.code === 0) {
          console.log('完成定义')
          this.$message.success('新增成功')
          this.$router.replace('/resource-require/service-type-manage')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    },
    // 添加及编辑流程的dialog 确定了表单
    addAndEditProcess (process) {
      console.log('add', process)
      // 存在id 则是进行编辑
      if (process.id) {
        const index = this.processList.findIndex(item => {
          return item.id === process.id
        })
        if (index !== -1) {
          this.processList[index] = process
        }
      // 不存在id,则是添加流程
      } else {
        process.id = getUid()
        this.processList.splice(length - 1, 0, process)
      }
    },
    // 删除流程
    deleteProcess (process) {
      const index = this.processList.findIndex(item => {
        return item.id === process.id
      })
      if (index !== -1) {
        this.processList.splice(index, 1)
      }
    },

    // 编辑流程
    editProcess (item, index) {
      this.curProcess = this.$clone(item)
      this.addProcessDialogVisible = true
    },
    // 打开添加流程的dialog
    openAddProcessDialog () {
      this.addProcessDialogVisible = true
    },
    // 添加流程的dialog 关闭
    onAddProcessDialogClose () {
      this.curProcess = {
        id: '',
        name: '',
        isFixed: false,
        fields: []
      }
    },

    // 完成
    finish () {

    }
  }
}
</script>

<style lang="scss" scoped>
  .step_three {
    display: flex;
    .label_box {
      width: 180px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #909399;
    }
    .content_box {
      width: 380px;
      .process_list {
        .process_item {
          width: 380px;
          height: 90px;
          background: #FFFFFF;
          border: 1px solid #DCDFE6;
          border-radius: 4px;
          margin-bottom: 20px;
          .item_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            padding: 0 10px;
            background-image: url('../../../../assets/images/price_bg.png');
            background-size: 100% 100%;
            & > h1 {
              font-size: 18px;
              font-weight: bold;
              line-height: 25px;
              color: #303133;
            }
            & > i {
              font-size: 20px;
              cursor: pointer;
              color: #F36A6E;
            }
          }
          .item_content {
            padding: 15px 20px;
            & > p {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              & > span {
                &:first-child {
                  color: #C0C4CC;
                  margin-right: 38px;
                }
                &:last-child {
                  color:#3473E6;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
