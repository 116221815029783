// 组件类型 常量
// const FIELDS = {
//   INPUT_TEXT: 'inputText'
// }

// 在添加表单项时下拉列表
const fieldTypeList = [
  {
    label: '文本框',
    value: 'inputText',
    dataType: 'varchar'
  },
  {
    label: '选择器',
    value: 'select',
    dataType: 'varchar'
  },
  {
    label: '图片上传',
    value: 'uploadImage',
    dataType: 'array'
  },
  {
    label: '文件上传',
    value: 'uploadFile',
    dataType: 'array'
  }
]

// 不同数据类型的的默认值 [text, varchar, array]
const dataTypeDefaultValue = {
  varchar: '',
  array: []
}

export {
  fieldTypeList,
  dataTypeDefaultValue
}
