/**
 * 固定字段的column是明确的，不可变的
 */
// 省份列表
import staticData from '@/configs/data'

// 默认表单字段
const defaultFields = [
  {
    column: 'title',
    componentType: 'inputText', // 字段类型
    dataType: 'varchar', // 数据类型
    display: '服务名称', // 字段label
    isFixed: 1, // 是否固定
    placeholder: '请输入服务名称', // 文本字段用的提示文字
    options: [],
    settings: {
      rules: [
        { required: true, message: '请输入服务名称', trigger: 'change' }
      ]
    }
  },
  {
    column: 'technicalId',
    componentType: 'selectTechnical', // 字段类型
    dataType: 'varchar', // 数据类型
    display: '技术领域', // 字段label
    isFixed: 1, // 是否固定
    placeholder: '', // 文本字段用的提示文字
    options: [],
    settings: {
      rules: [
        { required: true, message: '请选择技术领域', trigger: 'change' }
      ]
    }
  },
  {
    column: 'province',
    componentType: 'select', // 字段类型
    dataType: 'varchar', // 数据类型
    display: '服务地区', // 字段label
    isFixed: 1, // 是否固定
    placeholder: '', // 文本字段用的提示文字
    options: staticData.provinceList,
    settings: {
      rules: [
        { required: true, message: '请选择服务地区', trigger: 'change' }
      ]
    }
  },
  {
    column: 'description',
    componentType: 'textarea', // 字段类型
    dataType: 'text', // 数据类型
    display: '服务介绍', // 字段label
    isFixed: 1, // 是否固定
    placeholder: '请输入服务介绍', // 文本字段用的提示文字
    options: [],
    settings: {
      rules: [
        { required: true, message: '请输入服务介绍', trigger: 'blur' }
      ]
    }
  },
  {
    column: 'fee',
    componentType: 'price', // 字段类型
    dataType: 'varchar', // 数据类型
    display: '价格', // 字段label
    isFixed: 1, // 是否固定
    placeholder: '', // 文本字段用的提示文字
    options: ['面议', '具体值'],
    settings: {
      rules: [
        { required: true, message: '请输入或选择服务价格', trigger: 'change' }
      ]
    }
  },
  // -------------------为了方便王童添加-------------------
  {
    isFixed: 0,
    componentType: 'inputText',
    display: '关键字',
    placeholder: '请输入服务产品关键字',
    options: [],
    settings: {},
    column: '8jktrxlf5nywy81blnk6664x',
    dataType: 'varchar'
  },
  {
    isFixed: 0,
    componentType: 'select',
    display: '机构性质',
    placeholder: '',
    options: [
      '企业/事业单位',
      '事业单位',
      '企业'
    ],
    settings: {},
    column: 'yzfut7bkfff4mv7vdl96y0um',
    dataType: 'varchar'
  },
  {
    isFixed: 0,
    componentType: 'inputText',
    display: '服务资质',
    placeholder: '请输入您的服务资质，如：XX实验室，致命投融资公司',
    options: [],
    settings: {},
    column: '55ms3v06cmiiq7jqjj7u2w9y',
    dataType: 'varchar'
  },
  {
    isFixed: 0,
    componentType: 'select',
    display: '付款方式',
    placeholder: '',
    options: [
      '分三笔支付，30%，40%，30%',
      '一次性支付',
      '免费',
      '分两笔支付，30%，70%',
      '一事一议',
      '其他'
    ],
    settings: {},
    column: 'l3va2jihnd1hmsj9tfi6m6hv',
    dataType: 'varchar'
  },
  {
    isFixed: 0,
    componentType: 'inputText',
    display: '服务周期',
    placeholder: '请输入服务的服务周期，如：X天， X月-X月，X年',
    options: [],
    settings: {},
    column: 'xyt4jvm5mxeb95tdc1ghlelq',
    dataType: 'varchar'
  },
  {
    isFixed: 0,
    componentType: 'select',
    display: '服务验收方式',
    placeholder: '',
    options: [
      '客户项目成果验收',
      '样品交付',
      '专利交付和确权手续',
      '客户验收',
      '其他'
    ],
    settings: {},
    column: '8n1kxaocr75sbe0ncyzulhl2',
    dataType: 'varchar'
  },
  {
    isFixed: 0,
    componentType: 'select',
    display: '服务方式',
    placeholder: '',
    options: [
      '线上服务',
      '线下服务',
      '线上、线下服务'
    ],
    settings: {},
    column: '2ok22hsdz2igxelc00itm2c7',
    dataType: 'varchar'
  },
  {
    isFixed: 0,
    componentType: 'inputText',
    display: '服务条件',
    placeholder: '请输入需要客户提供的材料说明',
    options: [],
    settings: {},
    column: 'sjcxfnnqasdi5nwt8ltsxy37',
    dataType: 'varchar'
  },
  {
    isFixed: 0,
    componentType: 'inputText',
    display: '交付物名称',
    placeholder: '请输入服务完成后交付给用户的材料清单',
    options: [],
    settings: {},
    column: 'kzds0m129xw4k9skegshqvgk',
    dataType: 'varchar'
  },
  {
    isFixed: 0,
    componentType: 'inputText',
    display: '交付物类型',
    placeholder: '请输入服务完成后交付给用户的交付物形式，如：资金，报告等',
    options: [],
    settings: {},
    column: 'zekhqx315egf9zbob5qiweq9',
    dataType: 'varchar'
  }
  // -------------------为了方便王童添加-------------------
]

// 默认字段名
const defaultProps = {
  title: '',
  technicalId: '',
  province: '',
  description: '',
  fee: '',
  // -------------------方便王童添加-----------------------
  sjcxfnnqasdi5nwt8ltsxy37: '',
  yzfut7bkfff4mv7vdl96y0um: '',
  '8jktrxlf5nywy81blnk6664x': '',
  '55ms3v06cmiiq7jqjj7u2w9y': '',
  l3va2jihnd1hmsj9tfi6m6hv: '',
  xyt4jvm5mxeb95tdc1ghlelq: '',
  '8n1kxaocr75sbe0ncyzulhl2': '',
  '2ok22hsdz2igxelc00itm2c7': '',
  kzds0m129xw4k9skegshqvgk: '',
  zekhqx315egf9zbob5qiweq9: ''
  // -------------------方便王童添加-----------------------
}

export {
  defaultFields,
  defaultProps
}
