// 默认有两个流程
const defaultProcess = [
  {
    id: 'process-start',
    name: '已下单',
    isFixed: true,
    fields: []
  },
  {
    id: 'process-end',
    name: '已结束',
    isFixed: true,
    fields: []
  }
]

export {
  defaultProcess
}
