<template>
  <div class="step_two">
    <!-- 表单组件 -->
    <custom-form
      :formModel="fieldList"
      :formData="formData">
      <custom-form-item
        v-for="item in fieldList"
        :key="item.column"
        :item="item"
        :value.sync="formData[item.column]">
        <div
          v-if="!item.isFixed"
          class="item_icon_box tw-ml-10px"
          @click="deleteItem(item)">
          <i class="iconfont iconshanchu"></i>
        </div>
      </custom-form-item>
    </custom-form>

    <div class="add_btn_box">
      <div class="tw-mt-30px">
        <el-button size="small" @click="openAddFieldDialog"><i class="el-icon-plus"></i> 新增字段</el-button>
      </div>

      <div class="tw-mt-40px">
        <el-button size="small" type="primary" @click="updateCustomForm">下一步</el-button>
      </div>
    </div>

    <!-- 添加字段的弹窗 -->
    <add-field
      :visible.sync="addFieldDialogVisible"
      @addField="addField">
    </add-field>
  </div>
</template>

<script>
import * as api from '@/api'
import addField from './add-field'
import customForm from '@/components/custom-form/index'
import customFormItem from '@/components/custom-form/custom-form-item'
import { defaultFields, defaultProps } from '../utils/default.fields'
import { dataTypeDefaultValue } from '@/components/custom-form/utils/field.types'
export default {
  data () {
    return {
      // 添加字段的dialog
      addFieldDialogVisible: false,
      // 字段数组
      fieldList: [
        ...defaultFields
      ],
      // 表单数据
      formData: {
        ...defaultProps
      }
    }
  },
  props: {
    // 上一步表单所创建的服务类型
    formId: {
      type: String,
      default: ''
    }
  },
  components: {
    addField,
    customForm,
    customFormItem
  },
  methods: {
    // 更新自定义表单
    updateCustomForm () {
      this.btnLoading = true
      api.updateCustomForm({
        formId: this.formId,
        fields: this.fieldList
      }).then(res => {
        if (res.data.code === 0) {
          this.$emit('changeStep', 2, this.fieldList)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    },

    // 删除一个项
    deleteItem (item) {
      const index = this.fieldList.findIndex(fItem => {
        return fItem.column === item.column
      })
      if (index !== -1) {
        this.fieldList.splice(index, 1)
        this.$delete(this.formData, item.column)
      }
    },
    // 打开新增字段的弹窗
    openAddFieldDialog () {
      this.addFieldDialogVisible = true
    },
    // 添加字段
    addField (data) {
      this.fieldList.push(data)
      // 根据dataType 取出默认值 添加到表单对象中
      this.$set(this.formData, data.column, dataTypeDefaultValue[data.dataType])
    }
  }
}
</script>

<style lang="scss" scoped>
  .step_two {
    .add_btn_box {
      padding-left: 180px;
    }

    .item_icon_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #DCDFE6;
      border-radius: 2px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        background: #fff;
        border-color: #3473E6;
        color: #3473E6;
      }
    }
  }
</style>
