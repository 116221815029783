<!--
  创建服务类型
    + 三步，每次下一步都调用接口
 -->
<template>
  <div class="detail">
    <detail-header title="新增服务类型" />
    <div class="detail_main page_width">
      <div class="main_content">
        <el-steps :active="curStep" align-center finish-status="success">
          <el-step
            v-for="item in stepList"
            :key="item.value"
            :title="item.title">
          </el-step>
        </el-steps>
        <div class="step_wrapper">
          <component :is="curView" :formId="formId" :serviceTypeId="serviceTypeId" @changeStep="changeStep"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stepOne from './components/step-one'
import stepTwo from './components/step-two'
import stepThree from './components/step-three'
export default {
  data () {
    return {
      // 当前步骤
      curStep: 0,
      // 步骤条
      stepList: [
        {
          title: '基本信息',
          view: 'stepOne'
        },
        {
          title: '字段定义',
          view: 'stepTwo'
        },
        {
          title: '服务下单流程定义',
          view: 'stepThree'
        }
      ],
      // 服务类型id
      serviceTypeId: '',
      // 表单id
      formId: '',
      // 自定义表单数组
      fieldList: [],
      // 流程列表
      processList: []
    }
  },
  computed: {
    // 当前展示的组件
    curView () {
      return this.stepList[this.curStep].view
    }
  },
  components: {
    stepOne,
    stepTwo,
    stepThree
  },
  methods: {
    // 改变步骤
    changeStep (step, data) {
      console.log(data)
      if (step === 1) {
        this.formId = data.formId
        this.serviceTypeId = data.id
      } else if (step === 2) {
        this.fieldList = data
      }
      this.curStep = step
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 602px;
      margin: 0 auto;
      .step_wrapper {
        padding-top: 40px;
      }
    }
  }
}
</style>
