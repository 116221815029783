<!-- 添加字段 -->
<template>
  <el-dialog
    custom-class="my-el-dialog"
    title="新增字段"
    :visible.sync="$_visile"
    width="520px"
    :close-on-click-modal="false"
    append-to-body
    @close="onDialogClose">
    <el-form
      class="tw-px-20px"
      ref="form"
      :model="form"
      :rules="rules"
      label-position="left"
      label-width="100px"
      size="small">
      <el-form-item
        prop="componentType"
        label="字段类型">
        <el-select
          style="width:100%;"
          placeholder="请选择字段类型"
          v-model="form.componentType"
          @change="onComponentTypeChange">
          <el-option
            v-for="item in fieldTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        prop="display"
        label="字段名称">
        <el-input v-model="form.display" placeholder="请输入字段名称"></el-input>
      </el-form-item>

      <!-- 文本 -->
      <template v-if="form.componentType === 'inputText'">
        <el-form-item
          prop="placeholder"
          label="提示文字">
          <el-input v-model="form.placeholder" placeholder="请输入提示文字"></el-input>
        </el-form-item>
      </template>

      <!-- 选择器 -->
      <template v-if="form.componentType === 'select'">
        <el-row
          type="flex"
          justify="space-between"
          v-for="(optionItem, index) in form.options"
          :key="optionItem.key">
          <el-form-item
            :label="`选项${index + 1}`"
            :key="optionItem.key"
            :prop="`options[${index}].value`"
            :rules="{
              required: true, message: '选项内容不能为空', trigger: 'blur'
            }">
            <el-input style="width: 298px;" v-model="optionItem.value" placeholder="请输入选择器选项"></el-input>
          </el-form-item>
          <div class="item_icon_box" @click="deleteOptionItem(optionItem.key)">
            <i class="iconfont iconshanchu"></i>
          </div>
        </el-row>
        <el-form-item label-width="100px">
          <el-button size="small" @click="addOptionItem"><i class="el-icon-plus"></i> 新增选项</el-button>
        </el-form-item>
      </template>

    </el-form>
    <div slot="footer" class="footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
      <el-button type="primary" size="small" :loading="saveBtnLoading" @click="saveForm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { fieldTypeList } from '@/components/custom-form/utils/field.types'
import { getUid } from '@/utils/utils'
export default {
  data () {
    return {
      saveBtnLoading: false,
      // 添加表单项时
      form: {
        isFixed: 0,
        componentType: '', // 字段类型
        display: '', // 字段label
        placeholder: '', // 文本字段用的提示文字
        options: [{ // 当是选择器时的选项
          key: '',
          value: ''
        }],
        settings: {} // 表单项的配置项
      },
      rules: {
        componentType: [
          { required: true, message: '请选择字段类型', trigger: 'change' }
        ],
        display: [
          { required: true, message: '请输入字段名称', trigger: 'blur' }
        ],
        placeholder: [
          { required: true, message: '请输入提示文字', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    $_visile: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', false)
      }
    },
    // 字段类型
    fieldTypeList () {
      return fieldTypeList
    }
  },
  methods: {
    /**
     * 字段类型发生变化
     *  字段类型变化，移除校验结果
     */
    onComponentTypeChange (value) {
      this.$refs.form.clearValidate()
    },

    // 新增选项
    addOptionItem () {
      this.form.options.push({
        key: Date.now(),
        value: ''
      })
    },
    // 删除选项
    deleteOptionItem (key) {
      const index = this.form.options.findIndex(item => {
        return item.key === key
      })
      if (index !== -1) {
        this.form.options.splice(index, 1)
      }
    },

    // 进行保存
    saveForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = this.$clone(this.form)
          // 如果有选择器的话，把选项抽出来，否则从之选项数组
          if (data.componentType === 'select') {
            data.options = data.options.map(item => {
              return item.value
            })
          } else {
            data.options = []
          }
          // 字段名 测试用
          data.column = getUid()
          // 字段的数据类型
          data.dataType = this.findDataType(data.componentType)
          // 提交给父组件
          this.$emit('addField', data)
          this.closeDialog()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 重置表单
    resetForm () {
      this.$refs.form.resetFields()
      // 需要放在后面进行赋值
      this.form.options = [{
        key: '',
        value: ''
      }]
    },
    // 通过表单项类型查找数据类型
    findDataType (comType) {
      const field = fieldTypeList.find(item => {
        return item.value === comType
      })
      if (field) {
        return field.dataType
      } else {
        return new Error('未找到数据类型！')
      }
    },
    // 关闭新建里程碑的dialog
    closeDialog () {
      this.$_visile = false
    },
    // dialog关闭事件
    onDialogClose () {
      // 重置手段添加项目表单
      this.$refs.form.resetFields()
      // 需要放在后面进行赋值
      this.form.options = [{
        key: '',
        value: ''
      }]
      this.$emit('onDialogClose')
    }
  }
}
</script>
<style lang="scss" scoped>
  .item_icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #DCDFE6;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: #fff;
      border-color: #3473E6;
      color: #3473E6;
    }
  }
</style>
